import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './index.css';
import Licitatie from './licitatie/Licitatie';
import Anunt from './anunt/Anunt';
import AnuntCase from './AnuntCase/AnuntCase';
import LicitatieDesktop from './licitatieDesktop/LicitatieDesktop';
import AllLicitatiiJudet from './allLicitatieJudet/AllLicitatiiJudet';
import AllAnunturiJudet from './allAnunturiJudet/AllAnunturiJudet';
import AllAnunturiCaseJudet from './allAnunturiCaseJudet/AllAnunturiCaseJudet';
import Contact from './contact/Contact';
import Map from './Map';
import MapMobile from './MapMobile';
import AnunturiMobile from './AnunturiMobile';
import reportWebVitals from './reportWebVitals';
import Search from './search/Search';
import SearchResults from './search/SerchResults';

let savedJud = null;

// Pentru a afisa o alta componenta daca se foloseste ecran mic
function MapVersion() {
  const isMobile = window.innerWidth < 800;
  if ( isMobile ) {
    return <MapMobile />;
  }
  else {
    return <Map />;
  }
}

function MapVersionAnunturi() {
  const isMobile = window.innerWidth < 800;
  if ( isMobile ) {
    return <AnunturiMobile />;
  }
  else {
    return <Map />;
  }
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MapVersion dropJud={savedJud} />} />
        <Route path="/anunturi" element={<MapVersionAnunturi dropJud={savedJud} />} />
        <Route path="/id/:licitatieId" element={<Licitatie />} onClose={ (jud) => savedJud = jud } />
        <Route path="/case/id/desktop/:anuntId" element={<AnuntCase />} onClose={ (jud) => savedJud = jud } />
        <Route path="/anunt/:anuntId" element={<Anunt />} onClose={ (jud) => savedJud = jud } />
        <Route path="/id/desktop/:licitatieId" element={<LicitatieDesktop />} onClose={ (jud) => savedJud = jud } />
        <Route path="/judet/:judet" element={<AllLicitatiiJudet />} />
        <Route path="/anunturi/judet/:judet" element={<AllAnunturiJudet />} />
        <Route path="/case/judet/:judet" element={<AllAnunturiCaseJudet />} />
        <Route path="/contact/mobile" element={<Contact />} />
        <Route path="/search/mobile" element={<Search />} />
        <Route path="/searchresults/mobile" element={<SearchResults />} />
        <Route path="/" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
