import MockJudeteDataService from "../mock/MockJudeteDataService";

const JudeteDataService = {
    // Salvez datele primite pentru landing page  de la apelul la getJudData
    lastResponse: null,

    // Salvez ultimul raspuns cerut la pagina /anunturi
    lastResponseAnunturiData: null,

    // Salvez ultimul raspuns cerut la dropdown. Il foloses cand revin de la pagina Licitatii.js
    lastGetRaportDataJudet: null,

    // Folosesc pentru a sti ce judet sa deschid in pagina principala cand revin de la detalii
    dropJud: null,

    // Toate licitatiile primite la ultimul apel
    licitatiiAll: [],

    // Judetul pe care sa il afisez la pagina tuturor judetelor
    licitatiiAllJud: "",

    // Licitatia selectata. O salvez aici pentru a putea colora randul ei, dupa ce revin la pagina tuturor licitatiilor
    licitatiiAllSelected: 0,

    // Ultima licitatieId selectat din dropdown
    lastDropdownSelectedId: 0,

    // Numarul de anunturi pe care il voi afisa in pagina AllLicitatieJudet
    licitatiiAllAnunturiCount: 0,

    // Toate anunturile primite la ultimul apel
    anunturiAll: [],

    // Judetul pe care sa il afisez la pagina tuturor judetelor
    anunturiAllJud: "",

    // Anuntul selectata. Il salvez aici pentru a putea colora randul lui, dupa ce revin la pagina tuturor anunturilor
    anunturiAllSelected: 0,

    // Numarul de anunturi pe care il voi afisa in pagina AllAnunturiJudet
    anunturiAllAnunturiCount: 0,

    // Rezultatele care vor fi afisare pentru cautare
    searchResults: [],

    // O folosesc sa afisez mesaje de loading
    searchInProgress: false,

    // O folosesc pentru a completa inputul de search cand user alega Back
    lastSearchInput: "",

    // Lista cu o functie care o apelez cand primesc raspunsurile. Este o functie care primeste ca parametru lista de rezultate
    doOnSearch: [],
    registerOnSearchReceived: function( callback ) {
        this.doOnSearch = [ callback ];
    },

    getJudData: async function() {
        // return MockJudeteDataService.getJudData();

        let emtptyResponse = {
            judete: [],
            data_scanare: 0,
            numar_anunturi: 0,
            numar_anunturi_azi: 0,
            numar_licitatii: 0,
            licitatii_salvate: [],
        }

        if ( this.lastResponse != null) {
            return this.lastResponse;
        }
        else {
            try {
                const response = await fetch("/statistici-api/landing-page-data/", {
                    "method": "GET"
                });
                const data =  await response.json();

                // Salvez datele primite pentru landing page 
                this.lastResponse = data;

                return data;
            }
            catch(error) {
                if (error.message.includes("The string did not match the expected pattern") ) {
                    this.lastResponse = emtptyResponse;
                }

                return emtptyResponse;
            }
        }
    },

    getAnunturiJudData: async function() {
        if ( this.lastGetRaportDataJudet != null) {
            return this.lastGetRaportDataJudet;
        }
        else {
            const response = await fetch("/statistici-api/anunturi-page-data/", {
                "method": "GET"
            });
            const data =  await response.json();

            // Salvez datele primite pentru anunturi
            this.lastGetRaportDataJudet = data;

            return data;
        }
    },

    // Returneaza un obiect ce contine proprietatile:
    // judet
    // licitatii_selectate
    // licitatii_all
    getRaportDataJudet: function(judet) {
        // return MockJudeteDataService.getRaportDataJudet(judet);

        let url = "/statistici-api/raport/{judet}/".replace("{judet}", judet);
        return fetch( url, {
            "method": "GET"
        }).then( response => response.json());
    },

    getLicitatie: function(id) {
        let url = "/statistici-api/licitatie/{id}/".replace("{id}", id);
        return fetch( url, {
            "method": "GET"
        }).then( response => response.json());
    },

    getAnunt: function(id) {
        let url = "/statistici-api/anunt/{id}/".replace("{id}", id);
        return fetch( url, {
            "method": "GET"
        }).then( response => response.json());
    },

    getAnuntCase: function(id) {
        // return MockJudeteDataService.getAnuntCase(id);

        let url = "/statistici-api/anunt-case/{id}/".replace("{id}", id);
        return fetch(url, {
            "method": "GET"
        }).then( response => response.json());
    },

    pullData: function(id) {
        let url = "/statistici-api/licitatie-pull-data/{id}/".replace("{id}", id);
        return fetch( url, {
            "method": "GET"
        }).then( response => response.json());
    },

    downloadReport: function(judet) {
        return fetch("/statistici-api/download-raport/judet/".replace("judet", judet), {
            "method": "GET"
        });
    },

    downloadGraficAnunturi: function(judet) {
        return fetch("/statistici-api/download-grafic-anunturi/judet/".replace("judet", judet), {
            "method": "GET"
        });
    },

    saveLicitatie: function( licitatieId ) {
        let url = "/statistici-api/save/{id}/".replace("{id}", licitatieId);
        return fetch( url, {
            "method": "GET"
        }).then( response => response.json());
    },

    removeLicitatie: function( licitatieId ) {
        let url = "/statistici-api/remove/{id}/".replace("{id}", licitatieId);
        return fetch( url, {
            "method": "GET"
        }).then( response => response.json());
    },


    raporteazaLicitatie: function( licitatieId ) {
        let url = "/statistici-api/raporteaza/{id}/".replace("{id}", licitatieId);
        return fetch( url, {
            "method": "GET"
        }).then( response => response.json());
    },


    searchLicitatiiObs(search) {
        // Nu pornesc cautarea pentru valoare falsy 
        if (!search) {
            this.doOnSearch.map( f => f([]));
            this.doOnSearch = [];
            return;
        }

        // Nu pornesc cautarea pentru valoarea precedenta
        if (search === this.lastSearchInput)
        {
            this.doOnSearch.map( f => f(this.searchResults));
            this.doOnSearch = [];
            return;
        }

        this.lastSearchInput = search;
        this.searchInProgress = true;
        this.searchResults = []
        this.searchLicitatii(search).then( result => {
            this.searchResults = result['search_results'];
            this.doOnSearch.map( f => f(this.searchResults));
            this.doOnSearch = [];
            this.searchInProgress = false;
        });
    },

    searchLicitatii: function( search ) {
        let url = "/statistici-api/search/{searchValue}/".replace("{searchValue}", search);
        return fetch( url, {
            "method": "GET"
        }).then( response => response.json());
    }

}

export default JudeteDataService;