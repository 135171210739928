import React from "react";
import { Link } from "react-router-dom";
import LicitatiiList from "../list/LicitatiiList";
import Salvata from "../Salvata";
import JudeteDataService from '../services/JudeteDataService';

import './SearchResults.css';

const ORDER_BY_MP_PRICE = 1;
const ORDER_BY_SURFACE = 2;
const ORDER_BY_TOTAL_PRICE = 3;
const ORDER_BY_ID = 4;

class ResultsRowsMobil extends React.Component {

    render() {
        return (
            <div id="judete-container" className='unselectable' >
                
                <div id="header" className="licitatie-row unselectable">
                    <div onClick={ () => this.props.orderBy(ORDER_BY_ID) }>Id ↓</div>
                    <div className="all-licitatii-total-price-header" onClick={ () => this.props.orderBy(ORDER_BY_TOTAL_PRICE) }>Total ↓</div>
                    <div onClick={ () => this.props.orderBy(ORDER_BY_SURFACE) }>Surface↓</div>
                    <div onClick={ () => this.props.orderBy(ORDER_BY_MP_PRICE) }>P/mp ↓</div>
                    <div id="salvata-header"><Salvata salvata={1}/></div>
                </div>

                <LicitatiiList licitatii={this.props.licitatii}/>

            </div>
        )
    }
}

class SearchResults extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            results: [],
            message: "Se cauta..."
        }
    }

    orderBy(criteriu) {
        let sortedLicitatii;
        if ( criteriu === ORDER_BY_MP_PRICE ) {
            sortedLicitatii = this.state.results.sort( function( licitatieA, licitatieB ) {
                return licitatieA.price_mp - licitatieB.price_mp;
            });
        }
        
        if ( criteriu === ORDER_BY_SURFACE ) {
            sortedLicitatii = this.state.results.sort( function( licitatieA, licitatieB ) {
                return licitatieA.surface - licitatieB.surface;
            });
        }

        if ( criteriu === ORDER_BY_TOTAL_PRICE ) {
            sortedLicitatii = this.state.results.sort( function( licitatieA, licitatieB ) {
                return licitatieA.total_price - licitatieB.total_price;
            });
        }

        if ( criteriu === ORDER_BY_ID ) {
            sortedLicitatii = this.state.results.sort( function( licitatieA, licitatieB ) {
                return parseInt(licitatieA.id) - parseInt(licitatieB.id);
            });
        }

        this.setState({
            results: sortedLicitatii,
        })
    }


    componentDidMount(){
        if ( JudeteDataService.searchInProgress ) {
            this.setState({ message: "Se cauta..."});
        }
        else {
            this.setState({ message: JudeteDataService.searchResults.length + " Rezultate"});
        }

        this.setState({ results: JudeteDataService.searchResults });
        
        // Inregistrez si acest callback in caz ca nu au venit inca rezultatele
        // Dupa ce primesc raspunsul se va anula.
        // Pot sti ca, cautarea este in progress cat timp exista functia in JudeteDataService.doOnSearch
        JudeteDataService.registerOnSearchReceived( (searchResults) => {
            this.setState({ 
                results: searchResults,
                message: searchResults.length + " Rezultate" 
            });
        });
    }

    render() {
        return (
            <div id="search-results-container">

                {/* LISTA JUDETE BUTTON */}
                <Link id="link" to={`/`}><div className="button-direction">Lista judete</div></Link>

                {/* CARDUL REZULTATE GASITE */}
                <div id="card-container" className='unselectable'>
                    <div id="title">{this.state.message}</div>
                </div>

                { !!this.state.results && <ResultsRowsMobil licitatii={this.state.results} orderBy={(c) => this.orderBy(c)} /> } 

            </div>
        );
    }

}

export default SearchResults;