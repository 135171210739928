import React from "react";
import { Text, Rect, Group } from 'react-konva';

import './CountersDesktop.css';

class CountersDesktop extends React.Component {

    countersLocation(textLocationX, textLocationY) {
        return {
            rect: {
                X: textLocationX - 10,
                Y: textLocationY + 25
            },
            text: {
                X: textLocationX,
                Y: textLocationY + 32,
            }
        }
    }

    render() {

        const anunturi = !!this.props.counters ? this.props.counters.anunturi : this.props.anunturi;
        const licitatii = !!this.props.counters ? this.props.counters.licitatii : this.props.licitatii;
        const anunturi_case = !!this.props.counters ? this.props.counters.anunturi_case : 0;
        const licitatii_case = !!this.props.counters ? this.props.counters.licitatii_case : 0;

        return (
            <Group>
                <Rect x={this.countersLocation(this.props.x, this.props.y).rect.X} 
                    y={this.countersLocation(this.props.x, this.props.y).rect.Y} 
                    width={95} 
                    height={40} 
                    cornerRadius={5} 
                    stroke={"#B9726E"} 
                    strokeWidth={1} 
                    opacity={this.props.opacity} 
                    onClick={this.props.onClick}/> 

                <Text text={"Teren: " + anunturi + "/" + licitatii} 
                    x={this.countersLocation(this.props.x, this.props.y).text.X} 
                    y={this.countersLocation(this.props.x, this.props.y).text.Y} 
                    fill="#E6D2D1" 
                    opacity={this.props.opacity}
                    onClick={this.props.onClick} />

                <Text text={"Case: " + anunturi_case + "/" + licitatii_case} 
                    x={this.countersLocation(this.props.x, this.props.y).text.X} 
                    y={this.countersLocation(this.props.x, this.props.y).text.Y + 14} 
                    fill="#E6D2D1" 
                    opacity={this.props.opacity}
                    onClick={this.props.onClick} />
            </Group>
        )
    }
}

export default CountersDesktop;