import React from "react";
import { Link } from "react-router-dom";

import './Contact.css';

class Contact extends React.Component {
    render () {
        return (
            <div id="contact-container">

                {/* LISTA JUDETE BUTTON */}
                <Link id="link" to={`/`}><div className="button-direction">Lista judete</div></Link>

                <div id="card-container" className='unselectable'>
                    <div id="title">Contact</div>
                    <div>Puteti contacta echipa Licitatii Terenuri Romania la:</div>
                    <div><br/></div>
                    <div>E-mail: licitatii@binaryfusion.ro</div>
                </div>
            </div>
        )
    }
}

export default Contact;