import MockModels from "./MockModels";

const MockJudeteDataService = {
  getJudData: async function() {
      return new Promise((resolve, reject) => {
        const brasov = { nume: 'brasov', anunturi: 5, anunturi_case: 7, licitatii: 6, licitatii_case: 8, enabled: true}
        const cluj = { nume: 'cluj', anunturi: 5, anunturi_case: 7, licitatii: 6, licitatii_case: 8, enabled: true}
        setTimeout(() => {
            const data = {
              'data_scanare': '',
              'judete': [brasov, cluj],
              'numar_anunturi': 20,
              'numar_anunturi_azi': 10,
              'numar_licitatii': 30,
              'licitatii_salvate': 1,
            };
            resolve(data);
        }, 1000);
      });
  },

  getRaportDataJudet: async function(judet) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const data = {
          'judet': 'brasov', 
          'licitatii_selectate': MockModels.getAmountSelectate(5),
          'anunturi_selectate': MockModels.getAmountSelectate(5),
          'case_selectate': MockModels.getAmountSelectate(5),
          'licitatii_all': MockModels.getAmountSelectate(20),
          'anunturi_all': [],
          'anunturi_case_all': MockModels.getAmountSelectate(10),
          'anunturi_count': 20
        };
        resolve(data);
      }, 500);
    });
  },

  getAnuntCase: async function(anuntId) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(MockModels.getAnuntDetails(anuntId)); 
      }, 600);
    });
  }
}

export default MockJudeteDataService;

