import React from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import './AllAnunturiCaseJudet.css'
import JudeteDataService from '../services/JudeteDataService';

const ORDER_BY_MP_PRICE = 1;
const ORDER_BY_SURFACE = 2;
const ORDER_BY_TOTAL_PRICE = 3;
const ORDER_BY_ID = 4;

class TotaluriJudet extends React.Component {

    render() {
        const isMobile = window.innerWidth < 800;
        if ( isMobile ) {
            return (
                <div id="totaluri-judet-container" className="unselectable">
                    <div id="title">{this.props.jud.toUpperCase()}</div>
                    <div id="desc">Totaluri in baza de date pentru judet:</div>
                    <div id="anunturi">{ this.props.anunturiCount }  Anunturi case</div>
                    <div id="licitatii">{ this.props.licitatiiCount } Licitatii case</div>
                    <div id="data-scanare">xxx Data scanare</div>
                </div>
            )
        }
        else {
            return (
                <div id="totaluri-wrapper-desktop">
                    <div id="totaluri-judet-container-desktop">
                        <div id="title">{this.props.jud.toUpperCase()}</div>
                        <div id="desc">Totaluri in baza de date pentru judet:</div>
                        <div id="anunturi">{ this.props.anunturiCount }  Anunturi case</div>
                        <div id="licitatii">{ this.props.licitatiiCount } Licitatii case</div>
                        <div id="data-scanare">xxx Data scanare</div>
                    </div>
                </div>
            )
        }
    }
}

class Salvata extends React.Component {
    render() {
        if ( this.props.salvata === 1 ) {
            return (
                <span className="interesanta-smaller">I</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}

class Raportata extends React.Component {
    render() {
        if ( this.props.raportata === 1 ) {
            return (
                <span className="raportata">R</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}


class AnunturiCaseMobil extends React.Component {

    getCssClassName(licitatieId) {
        let classes = "licitatie-row";
        if ( JudeteDataService.licitatiiAllSelected === licitatieId ) {
            classes += " selected";
        }
        return classes;
    }

    // Deschid direct pagina de detalii
    handleLicitatieClick(licitaiteSimplificata) {
        JudeteDataService.licitatiiAllSelected = licitaiteSimplificata.id;
        this.props.navigate("/id/" + licitaiteSimplificata.id );
    }

    render() {
        return (
            <div id="judete-container" className='unselectable' >
                
                <div id="header" className="licitatie-row unselectable">
                    <div onClick={ () => this.props.orderBy(ORDER_BY_ID) }>Id ↓</div>
                    <div className="all-licitatii-total-price-header" onClick={ () => this.props.orderBy(ORDER_BY_TOTAL_PRICE) }>Total ↓</div>
                    <div onClick={ () => this.props.orderBy(ORDER_BY_SURFACE) }>Surface↓</div>
                    <div onClick={ () => this.props.orderBy(ORDER_BY_MP_PRICE) }>P/mp ↓</div>
                    <div id="salvata-header"><Salvata salvata={1}/></div>
                </div>

                {this.props.anunturi.map( (anunturi, index) => (
                    <div key={index} onClick={() => this.handleLicitatieClick(anunturi)} >
                        <div className={this.getCssClassName(anunturi.id)}>
                            <div className='licitatie-id-smaller'>{anunturi.id}</div>
                            <div className='all-licitatii-total-price'>{anunturi.total_price}</div> 
                            <div>{anunturi.surface}</div> 
                            <div>{ parseFloat(anunturi.price_mp).toFixed(2)}</div> 
                            <div><Salvata salvata={anunturi.salvata}/></div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

// Sectiunea din pagina tuturor lictatiilor unui judet, care contine doar randurile inclusiv
// header-ul
class AnunturiCaseDesktop extends React.Component {

    getCssClassName(anuntId) {
        let classes = "case-row desktop";
        if ( JudeteDataService.licitatiiAllSelected === anuntId ) {
            classes += " selected";
        }
        return classes;
    }

    // Deschid direct pagina de detalii
    handleAnuntClick(anuntSimplificat) {
        JudeteDataService.licitatiiAllSelected = anuntSimplificat.id;
        this.props.navigate("/case/id/desktop/" + anuntSimplificat.id );
    }

    render() {
        return (

            <div id="judete-grid-container-desktop">


                {/* Coloana 1 */}
                <div></div> 

                {/* Coloana 2 */}
                <div id="judete-container" className='unselectable' >
                    {/* Header */}
                    <div id="header-case" className="case-row desktop">
                        <div id="header-id" onClick={ () => this.props.orderBy(ORDER_BY_TOTAL_PRICE) }>ID ↓</div>
                        <div onClick={() => {}}>Localitate</div>
                        <div onClick={ () => this.props.orderBy(ORDER_BY_TOTAL_PRICE) }>Total ↓</div>
                        <div onClick={ () => this.props.orderBy(ORDER_BY_SURFACE) }>Surface↓</div>
                        <div onClick={ () => this.props.orderBy(ORDER_BY_MP_PRICE) }>EUR/mp ↓</div>
                        <div><Salvata salvata={1}/></div>
                        <div><Raportata raportata={1}/></div>
                    </div>

                    {/* Randurile */}
                    {this.props.anunturi.map( (anunt, index) => (
                        <div key={index} onClick={() => this.handleAnuntClick(anunt)} >
                            <div className={this.getCssClassName(anunt.id)}>
                                <div className="id-wrapper">
                                    <div className='licitatie-id-smaller-desktop'>{anunt.id}</div>
                                </div>
                                <div>{anunt.localitate}</div>
                                <div>{anunt.total_price}</div> 
                                <div>{anunt.surface}</div> 
                                <div>{ parseFloat(anunt.price_mp).toFixed(2)}</div> 
                                <div><Salvata salvata={anunt.salvata}/></div>
                                <div><Raportata raportata={anunt.raportata}/></div>
                            </div>
                        </div>
                    ))}

                    {/* doar adaug un rand got pentru spatiu */}
                    <div className={this.getCssClassName(-1)}></div> 

                    <div className='mt-20 mb-20'>
                        <div><Salvata salvata={1}/> <span className='ml-20'>Interesanta - Un fel de bookmark care se pastreaza de la zi la zi</span></div><br/>
                        <div><Raportata raportata={1}/><span className='ml-10'>Raportata - Semnaleaza mie ca datele sunt extrase gresit</span></div>
                    </div>


                </div>

                {/* Coloana 3 */}
                <div></div> 

            </div>
        )
    }
}

// Pentru a putea folose functia useNavigate, am facut un wrapper function peste componenta AnunturiMobil
function AnunturiCaseMobilWithNavigate(props) {
    let navigate = useNavigate();
    return <AnunturiCaseMobil {...props} navigate={navigate} anunturi={props.anunturiAll} />
}

// Pentru a putea folose functia useNavigate, am facut un wrapper function peste componenta AnunturiDesktop
function AnunturiCaseDesktopWithNavigate(props) {
    let navigate = useNavigate();
    return <AnunturiCaseDesktop {...props} navigate={navigate} anunturi={props.anunturiAll } />
}

// Corespunde paginii tuturor licitatiilor dintr-un judet
class AllAnunturiCaseJudet extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anunturiAll: [],
            count: 0,
            jud: "judet"
        }
    }

    componentDidMount() {
        const judet = this.props.params.judet;

        this.setState({
            anunturiAll: JudeteDataService.anunturiAll,
            count: JudeteDataService.anunturiAll.length,
            anunturiCount: JudeteDataService.anunturiAllAnunturiCount,
            jud: JudeteDataService.anunturiAllJud
        });

        JudeteDataService.getRaportDataJudet(judet).then( response => {
            this.setState({
                anunturiAll: response.anunturi_case_all,
                count: response.anunturi_case_all.length,
                anunturiCount: response.anunturi_count,
                jud: judet
            });

            JudeteDataService.anunturiAll = response.anunturi_case_all;
            JudeteDataService.anunturiAllJud = judet;
            JudeteDataService.anunturiAllAnunturiCount = response.anunturi_count;
        });

        // Salvez detele pentru a sti ce judet sa deschid in pagina principala
        JudeteDataService.dropJud = judet;

        window.scrollTo(0, 0);
    }

    orderBy(criteriu) {
        let sortedAnunturi;
        if ( criteriu === ORDER_BY_MP_PRICE ) {
            sortedAnunturi = this.state.anunturiAll.sort( function( A, B ) {
                return A.price_mp - B.price_mp;
            });
        }
        
        if ( criteriu === ORDER_BY_SURFACE ) {
            sortedAnunturi = this.state.anunturiAll.sort( function( A, B ) {
                return A.surface - B.surface;
            });
        }

        if ( criteriu === ORDER_BY_TOTAL_PRICE ) {
            sortedAnunturi = this.state.anunturiAll.sort( function( A, B ) {
                return A.total_price - B.total_price;
            });
        }

        if ( criteriu === ORDER_BY_ID ) {
            sortedAnunturi = this.state.anunturiAll.sort( function( A, B ) {
                return parseInt(A.id) - parseInt(B.id);
            });
        }

        this.setState({
            anunturiAll: sortedAnunturi,
        })
    }

    render() {
        const isMobile = window.innerWidth < 800;
        if ( isMobile ) {
            return (
                <div>
                    <TotaluriJudet anunturiCount={this.state.anunturiCount} licitatiiCount={this.state.count} jud={this.state.jud} />

                    <AnunturiCaseMobilWithNavigate anunturiAll={this.state.anunturiAll} orderBy={(c) => this.orderBy(c)} />
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* LISTA JUDETE BUTTON */}
                    <Link className='link' to={`/`}><div className="button-direction">Harta</div></Link>
                    <TotaluriJudet anunturiCount={this.state.anunturiCount} licitatiiCount={this.state.count} jud={this.state.jud} />


                    <AnunturiCaseDesktopWithNavigate anunturiAll={this.state.anunturiAll} orderBy={(c) => this.orderBy(c)} />
                </div>
            )
        }
    }
}

// eslint-disable-next-line
export default (props) => (
    <AllAnunturiCaseJudet {...props} params={useParams()} /> 
);