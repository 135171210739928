const MockModels = {
  getLicitatieSelectata: function() {
    return {
      id:  Math.floor(Math.random() * 100),
      price_mp: Math.floor(Math.random() * 1000),
      detalii: '',
      salvata: 0,
      raportata: 0,
    };
  },

  // Are aceeasi structura ca si o licitatie
  getAnuntSelectat: function() {
    return {
      id:  Math.floor(Math.random() * 100),
      total_price: 2000 + Math.floor(Math.random() * 100),
      price_mp: Math.floor(Math.random() * 1000),
      surface: Math.floor(Math.random() * 200),
      detalii: '',
      salvata: 0,
      raportata: 0,
    };
  },

  getAmountSelectate: function(count) {
    let res = [];
    for(let i = 0; i < count; i++) {
      res.push(this.getAnuntSelectat());
    }
    return res;
  },

  getAnuntDetails: function(anuntId) {
    return {
      id: anuntId,
      title: 'Lorem ipsum anunt casa ' + anuntId,
      total_price: 10 + Math.floor(Math.random() * 2000),
      price_mp: 10 + Math.floor(Math.random() * 2000),
      price_unit: 10 + Math.floor(Math.random() * 2000),
      surface: 10 + Math.floor(Math.random() * 2000),
      judet: 'brasov',
      localitate: 'brasov',
      data_scanare: new Date().toDateString(),
      data_licitatie: '12 Feb 2024',
      detalii: 'https://www.olx.ro',
      salvata: 0,
      raportata: 0,
      pdf: '',
      content: 'content',
      cf: '',
      nr_cadastru: '',
      pulled: 0
    }
  }
}


export default MockModels;