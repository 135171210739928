import React from 'react';
import JudeteDataService from '../services/JudeteDataService';
import { Link } from "react-router-dom";

import './licitatiiSelectateDesktop.css';

class Salvata extends React.Component {
    render() {
        if ( this.props.salvata === 1 ) {
            return (
                <span className="interesanta">I</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}

class Raportata extends React.Component {
    render() {
        if ( this.props.raportata === 1 ) {
            return (
                <span className="raportata">R</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}

class LicitatiiSelectateDesktop extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            licitatiiSelectate: [],
            anunturiSelectate: [],
            licitatiiAll: [],
            visibility: 'case' // options: terenuri/case
        }
    }

    componentDidMount() {
        // Incerc sa iau din cache in loc sa mai fac un nou request
        if (!!JudeteDataService.lastGetRaportDataJudet && JudeteDataService.lastGetRaportDataJudet.judet === this.props.judet) {
            this.setState({
                licitatiiSelectate: JudeteDataService.lastGetRaportDataJudet.licitatii_selectate,
                anunturiSelectate: JudeteDataService.lastGetRaportDataJudet.case_selectate,
                licitatiiAll: JudeteDataService.lastGetRaportDataJudet.licitatii_all
            });

            JudeteDataService.licitatiiAll = JudeteDataService.lastGetRaportDataJudet.licitatii_all;
            JudeteDataService.licitatiiAllAnunturiCount = JudeteDataService.lastGetRaportDataJudet.anunturi_count;
            JudeteDataService.licitatiiAllJud = this.props.judet;
        }
        else {
            /**
             * Response contine datele unui raport pentru un judet
             * judet = ""
             * licitatii_selectate  - [ {  id: 0, price_mp: 0, detalii: "", salvata: 1 sau 0, "raportata": 1 sau 0 }, ... ]
             * licitatii_all        - lista cu obiecte licitatii simplificate: LicitatieSimplificataDetails
             * anunturi_count       - numarul de anunturi din baza de date, pentru judetul selectat
             */
            JudeteDataService.getRaportDataJudet(this.props.judet).then( response => {
                console.log(response.case_selectate);
                this.setState({
                    licitatiiSelectate: response.licitatii_selectate,
                    anunturiSelectate: response.case_selectate,
                    licitatiiAll: response.licitatii_all
                });
                JudeteDataService.lastGetRaportDataJudet = response;
                JudeteDataService.licitatiiAll = response.licitatii_all;
                JudeteDataService.licitatiiAllJud = this.props.judet;
                JudeteDataService.licitatiiAllAnunturiCount = response.anunturi_count;
            });
        }
    }

    /**
     * Trimite catre ruta componentei pentru detaliile licitatiei functie de marimea ecranului
     * pentru a alege intre Licitie.js in cazul ecran de mobil sau LicitatieDesktop.js in cazul
     * in care se foloseste ecran de desktop 
     */
    urlToLicitatieComponent(l) {
        if ( window.innerWidth < 800 ) {
            return `/id/` + l.id;
        }
        else {
            return `/id/desktop/` + l.id;
        }
    }

    urlToAnuntComponent(l) {
        if ( window.innerWidth < 800 ) {
            return `case/id/desktop` + l.id;
        }
        else {
            return `case/id/desktop/` + l.id;
        }
    }

    isLastSelected(licitatieId) {
        return parseInt(JudeteDataService.lastDropdownSelectedId) === parseInt(licitatieId);
    }

    selectCase() {
        this.setState({
            visibility: 'case'
        })
    }

    selectTerenuri() {
        this.setState({
            visibility: 'terenuri'
        })
    }

    addPadding(id) {
        return ('' + id).padStart(4, '◦');
    }

    urlToAllAnunturiComponent(jud) {
        switch(this.state.visibility) {
            case 'terenuri':
                return window.innerWidth < 800 ? `/anunturi/judet/${jud}` : `/anunturi/judet/${jud}`;
            case 'case':
            default:
                return window.innerWidth < 800 ? `/case/judet/${jud}` : `/case/judet/${jud}`;
        }
    }

    urlToAllLicitatiiComponent(jud) {
        switch(this.state.visibility) {
            case 'terenuri':
                return `/judet/${jud}`;
            case 'case':
            default:
                return `/judet/${jud}`;
        }
    }

    render() {
        return(
            <div id="licitatii-selectate-container">
                <div id="jud-img-reserved"></div>

                <div id="licitatii-selectate-column">
                    <div id="licitatii-selectate">
                        <img id="close-jud-btn" alt='close button' src="/img/close-btn-x.svg" onClick={() => this.props.onClose() } />

                        <div id="selectii-label" >
                            <div id="selectii-title">SELECTII {this.props.judet.toUpperCase()} - {this.state.visibility === 'case' ? 'CASE' : 'TERENURI'}</div>


                            <img className={this.state.visibility === 'case' ? 'selectii-label-vis' : 'selectii-label-vis not-selected'} alt='select case button' src="/img/case.svg" onClick={() => this.selectCase()} />
                            <img className={this.state.visibility === 'terenuri' ? 'selectii-label-vis' : 'selectii-label-vis not-selected'} alt='select terenuri button' src="/img/terrain.svg" onClick={() => this.selectTerenuri()} />
                        </div>

                        <div className='m-20'>Anunturi selectate dupa anumite criterii ca relevante. Momentan criteriul este - primele 5 anunturi cele mai mici pret / mp</div>

                        {/* Selectii pentru terenuri*/}
                        { this.state.visibility === 'terenuri' && this.state.licitatiiSelectate.map( (l, index) => (

                            <Link id="link" key={index} to={ this.urlToLicitatieComponent(l) }>
                                <div className='licitatie-selectata desktop' { ...this.isLastSelected(l.id) && { style: {background: '#5f1000'}}}>
                                    <span className='licitatie-id'>{this.addPadding(l.id)}</span> 
                                    <span className="pret desktop">{ parseFloat(l.price_mp).toFixed(2) + " EUR/mp" }</span>
                                    <Salvata salvata={l.salvata}/>
                                    <Raportata raportata={l.raportata}/>
                                </div>
                            </Link>
                            
                        ))}

                        {/* In caz ca nu este generat raportul */}
                        { this.state.visibility === 'case' && this.state.anunturiSelectate.length === 0 && 
                            <div className='m-20 grey-text'>
                                Nu sunt anunturi selectate pentru case. Raportul zilnic nu a fost generat.
                            </div>
                        }

                        { this.state.visibility === 'terenuri' && this.state.licitatiiSelectate.length === 0 && 
                            <div className='m-20 grey-text'>
                                Nu sunt anunturi selectate pentru terenuri. Raportul zilnic nu a fost generat.
                            </div>
                        }

                        {/* Selectii pentru case*/}
                        { this.state.visibility === 'case' && this.state.anunturiSelectate.map( (l, index) => (

                            <Link id="link" key={index} to={ this.urlToAnuntComponent(l) }>
                                <div className='licitatie-selectata desktop' { ...this.isLastSelected(l.id) && { style: {background: '#5f1000'}}}>
                                    <span className='licitatie-id' >{this.addPadding(l.id)}</span> 
                                    <span className="pret desktop">{ "TOTAL " + parseFloat(l.total_price).toFixed(0) + " EUR - " + parseFloat(l.price_mp).toFixed(2) + " EUR/mp" }</span>
                                    <Salvata salvata={l.salvata}/>
                                    <Raportata raportata={l.raportata}/>
                                </div>
                            </Link>
                            
                        ))}

                        <div className="m-20 row evenly">
                            <Link id="link-toate-anunturile" className='no-underline' to={this.urlToAllAnunturiComponent(this.props.judet)} onClick={this.props.onClose}>
                                <div id="toate-anunturile" className="button desktop" >TOATE ANUNTURILE</div>
                            </Link>

                            <Link id="link-toate-licitatiile" className='no-underline' to={this.urlToAllLicitatiiComponent(this.props.judet)}  onClick={this.props.onClose}>
                                <div id="toate-licitatiile" className="button desktop" >TOATE LICITATIILE</div>
                            </Link>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default LicitatiiSelectateDesktop;