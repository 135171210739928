import React  from "react";

import './JudDetailsPanel.css';
import JudeteDataService from './services/JudeteDataService';

class JudDetailsPanel extends React.Component {

    downloadReport(judet) {
        JudeteDataService.downloadReport(judet).then( response => {

            if ( response.status !== 200) {
                alert("Not posible. Server error");
                return;
            }

            response.blob().then( blob => {
                let url = window.URL.createObjectURL(blob);
                let a  = document.createElement('a');
                a.href = url;
                a.download = judet + '.pdf';
                a.click();
            })
        }, (reason) => {
            alert("Not posible. " + reason);
        });
    }

    render() {
        return (

            <div id="jud-details-container">
                <div id="panel-and-inchide">
                    <div id="jud-details-panel">
                        <div id="title">{ this.props.details.judet.toUpperCase() }</div>
                        <div className="row">{ this.props.details.jud_anunturi } Anunturi terenuri</div>
                        <div className="row">{ this.props.details.jud_licitatii } Licitatii terenuri</div>
                        <div className="row">{ this.props.details.jud_anunturi_case } Anunturi case</div>
                        <div id="push"></div>
                        <div onClick={() => this.downloadReport( this.props.details.judet )} id="raport-button">DESCARCA RAPORT</div>

                        {/* Am mutat butonul asta in LicitatiiSelectate/Desktop */}
                        {/* <Link id="link-toate" to={`/judet/${this.props.details.judet}`}>
                            <div id="toate-button" className="desktop" >TOATE LICITATIILE</div>
                        </Link> */}
                    </div>

                    <div id="btn-inchide" onClick={() => this.props.onClose() } >Inchide</div>
                </div>
            </div>
        )
    };
}

export default JudDetailsPanel;